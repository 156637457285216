import { DeployConfig } from '~/composables/useAppConfig'

type Fetch = (input: RequestInfo, init?: RequestInit) => Promise<Response>

export const deployConfig = async (fetch: Fetch) => {
  const result: Record<string, string> = {}
  const response = await fetch(`/assets/app.config.json?${new URLSearchParams({
    v: '4'
  }).toString()}`)

  if (response?.ok) {
    const configList = await response.json()

    configList.forEach((configItem: { Name: string, Value: string }) => {
      result[configItem.Name] = configItem.Value
    })
  }

  return result as unknown as DeployConfig
}
